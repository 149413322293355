<!-- 语法 -->
<template>
  <div class="">
     <pl35he></pl35he>
    <pl35fu></pl35fu>
    <pl35dan></pl35dan>
   
    <pl3wei></pl3wei>
    <pl5wei></pl5wei>
  </div>
</template>

<script>
import pl35fu from "@/components/wanfa/pl35fu.vue";
import pl35dan from "@/components/wanfa/pl35dan.vue";
import pl35he from "@/components/wanfa/pl35he.vue";
import pl3wei from "@/components/wanfa/pl3wei.vue";
import pl5wei from "@/components/wanfa/pl5wei.vue";
//import '../js/font.js' //js文件引入，注意路径
//import {myfun} from '../js/test.js' //js文件引入，注意路径
//import Introduction from '@/views/Introduction.vue'
//import api from '@/js/method'
export default {
  name: "",
  components: {
     pl35fu,
    pl35dan,
    pl35he,
    pl3wei,
    pl5wei
  },
  data() {
    // 数据源
    return {};
  },
  mounted() {},
  computed: {
   
  },
  methods: {
    // 函数体部分，js的主要逻辑控制
  },
  props: {},
};
</script>

<style scoped>
/* @import './swiper.css'; */
</style>